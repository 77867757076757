<template>
    <div class="submitSuccess">
        <van-empty>
            <template #description>
              <div style="color:red">
                提交成功！
              </div>
              <!-- <div style="color:red">
                活动中心将在两个工作日内与您取得联系！
              </div> -->
            </template>
            <van-button @click="toClose" round block type="info" class="bottom-button">
              关闭当前页面
            </van-button>
        </van-empty>
        
    </div>
</template>

<script>
    import { Empty, Field, NumberKeyboard } from 'vant';
    export default {
        name:'submitSuccess',
        components: {
            [Empty.name]: Empty,
            [Field.name]: Field,
            [NumberKeyboard.name]: NumberKeyboard,
        },
        data() {
            return {
                value: '',
                show:false,
                zhuqian:0,
                btnType: 0
                
            }
        },
        created(){
          this.zhuqian = this.$route.query.zhuqian
          this.btnType = this.$route.query.btnType
        },
        methods: {
            toClose() {
                wx.closeWindow()
            },
        },
    }
</script>

<style lang="less">
    .submitSuccess{
        .bottom-button{
            width: 160px;
            height: 40px;
            background-color: #00DE93;
            border: 1px solid #00DE93;
        }
        .van-empty__description{
          // color: red;
          padding: 0;
          text-align: center;
        }
    }
      .keyBroad-transform-leave-active,
  .keyBroad-transform-enter-active {
    transition: all .3s;
  }
  .keyBroad-transform-enter {
    opacity: 0;
    transform: translateY(30px);
  }
  .keyBroad-transform-leave-to {
    opacity: 0;
    transform: translateY(30px);
  }

.numKeyBroad {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  li { 
    width: 32%;
    height: 54px;
    font-size: 24px;
    font-style: normal; 
    line-height: 54px;
    text-align: center;
    vertical-align: middle;
    border: 0 solid #f7f7f7;
    border-width: 1px 1px 0 0; 
    background-color: #fff;
    flex: 1 0 auto;
  }
  li:active {
    background-color: #f2f3f5;
  }
  .delText {
    background-color: #ebedf0;
    font-size: 16px;
  }
  .dot {
    background-color: #ebedf0;
  }
}
</style>